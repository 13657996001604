
import {
  computed,
  defineComponent,
  useFetch,
  useRoute,
  useContext,
} from '@nuxtjs/composition-api';
import { usePage } from '@/composables';

const PAGES_WITHOUT_HEADER = ['hexagon'];

export default defineComponent({
  setup() {
    const route = useRoute();
    const { error } = useContext();
    const slug = computed(() => route.value.params.slug);
    const { page, fetchPage, isLoading } = usePage(slug.value);

    const { fetchState } = useFetch(async () => {
      await fetchPage();
    });

    const isCategoryHeaderShouldShow = computed(
      () => !PAGES_WITHOUT_HEADER.includes(route.value.params.slug),
    );
    if (fetchState.error && !fetchState.pending)
      error({
        statusCode: 404,
        statusMessage: 'Page Not Found',
      });

    return {
      page,
      isLoading,
      isCategoryHeaderShouldShow,
    };
  },
  head: {},
});
